<template>
    <div class="page products-page" v-if="pageIsReady">
        <div class="page__title">
            <nav-back />
            Список товаров
        </div>
        <div class="products-page__list">
            <div v-for="(product, i) of products" :key="i" class="products-page__item">
                <label>ID:</label>
                <input type="text" class="form-control" disabled v-model="product.id" />
                
                <label>product_id:</label>
                <input type="text" class="form-control" v-model="product.product_id" />
                
                <label>google_id:</label>
                <input type="text" class="form-control" v-model="product.apple_id" />
                
                <label>apple_id:</label>
                <input type="text" class="form-control" v-model="product.apple_id" />
                
                <label>first_bonus:</label>
                <input
                    type="number" v-model.number="product.first_bonus" min="0" oninput="validity.valid||(value=0)"
                    class="form-control"
                />
                
                <label>is_first:</label>
                <div class="d-flex flex-row align-items-center">
                    <input type="checkbox" v-model="product.is_first" class="form-control w-auto m-0 mr-2" />
                    <span>{{ product.is_first ? 'On' : 'Off' }}</span>
                </div>
                
                <label>Rewards</label>
                <div class="products-page__item-rewards">
                    <div v-for="(reward, j) of product.rewards" :key="j">
                        <b-dropdown :text="getCurrencyName(rewardOptions[product.id][j])">
                            <b-dropdown-item
                                v-for="(currency, i) of options" :key="i"
                                @click="onCurrencySelect($event, product.id, j)"
                                :data-value="currency.value"
                            >
                                {{ currency.name }}
                            </b-dropdown-item>
                        </b-dropdown>
                        <input
                            type="number" v-model.number="reward.value" min="0"
                            oninput="validity.valid||(value=0)"
                            class="form-control"
                        />
                    </div>
                </div>
                
                <ui-button @click="saveProduct(product.id)" class="w-auto">Сохранить</ui-button>
            </div>
        </div>
    </div>
</template>

<script>
import Vue from 'vue';
import { mapActions } from 'vuex';
import bus from '@/plugins/bus';
import { clone, getCurrencyName } from '@/utils';

export default {
    data: () => ({
        pageIsReady: false,
        products: [],
        rewardOptions: {},
        locked: false
    }),
    components: {
        NavBack: () => import('../../components/navigation/NavBack'),
        UiButton: () => import('../../components/ui/UiButton'),
    },
    computed: {
        options() {
            return this.$store.getters['firebase/currenciesOptions'];
        },
    },
    async created() {
        bus.$off(this.FIREBASE_CONFIG_CHANGED_EVENT, this.onConfigChanged)
            .$on(this.FIREBASE_CONFIG_CHANGED_EVENT, this.onConfigChanged);
        
        await this.getConfig();
        
        this.pageIsReady = true;
        this.products = this.$store.getters['firebase/products'];
        this.products.forEach(
            product => this.rewardOptions[product.id] = product.rewards.map(reward => reward.id)
        );
    },
    beforeDestroy() {
        bus.$off(this.FIREBASE_CONFIG_CHANGED_EVENT, this.onConfigChanged);
    },
    methods: {
        ...mapActions('firebase', ['getConfig']),
        getCurrencyName(id) {
            return getCurrencyName(id);
        },
        onConfigChanged() {
            this.pageIsReady = false;
            setTimeout(() => this.pageIsReady = true, 0);
        },
        onCurrencySelect(event, productId, rewardIndex) {
            const newValue = +event.target.dataset.value;
            const rewardOptions = clone(this.rewardOptions[productId]);
            
            rewardOptions[rewardIndex] = newValue;
            Vue.set(this.rewardOptions, productId, rewardOptions);
            
            const product = this.products.find(product => product.id === productId);
            product.rewards[rewardIndex].id = newValue;
            
            this.$forceUpdate();
        },
        async saveProduct(productId) {
            this.locked = true;
            await this.$store.dispatch('firebase/saveProduct', this.products.find(p => p.id === productId));
            this.locked = false;
        }
    },
};
</script>

<style lang="scss">
.products-page {
    &__item {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        border: 1px solid #9AAABC;
        padding: 1.5rem;
        border-radius: 24px;
        margin-bottom: 1rem;
        
        > * {
            width: 50%;
            text-align: left;
        }
        
        input {
            margin-bottom: 1rem;
        }
        
        label {
            font-weight: 600;
        }
        
        &-rewards {
            .dropdown {
                margin-bottom: .5rem;
            }
        }
    }
}
</style>
