<template>
    <div class="page recommended-stories-page">
        <div class="page__title">
            <nav-back/>
            Порядок рекомендованных историй
        </div>
        <div class="recommended-stories-page__list">
            <draggable
                class="list-group"
                tag="ul"
                v-model="storiesList"
                v-bind="dragOptions"
                @start="dragging = true"
                @end="dragging = false"
                @change="onOrderChanged"
                :move="onStoryMoved"
            >
                <transition-group type="transition" name="flip-list" class="d-flex flex-wrap">
                    <li
                        class="list-group-item recommended-stories-page__story-item"
                        v-for="story in storiesList"
                        :key="story.id"
                    >
                        {{ story.order_recommended || 0 }}: {{ story.title }} (ID: {{ story.id }})
                    </li>
                </transition-group>
            </draggable>
        </div>
    </div>
</template>

<script>
import draggable from "vuedraggable";
import {mapActions, mapGetters} from 'vuex';
import bus from '@/plugins/bus';
import {sortByAttrDesc} from '@/utils';

export default {
    data: () => ({
        dragging: false,
        storiesList: [],
        dragLocked: false
    }),
    components: {
        draggable,
        NavBack: () => import('../../components/navigation/NavBack')
    },
    computed: {
        ...mapGetters('firebase', ['stories']),
        dragOptions() {
            return {
                animation: 0,
                group: 'description',
                disabled: false,
                ghostClass: 'ghost'
            };
        },
    },
    async created() {
        await this.getConfig();
        this.setData();
        bus.$off(this.FIREBASE_CONFIG_CHANGED_EVENT, this.setData).$on(this.FIREBASE_CONFIG_CHANGED_EVENT, this.setData);
    },
    beforeDestroy() {
        bus.$off(this.FIREBASE_CONFIG_CHANGED_EVENT, this.setData);
    },
    methods: {
        ...mapActions('firebase', ['getConfig', 'updateStoriesRecommendedOrder']),
        setData() {
            this.storiesList = this.stories.map(story => {
                return {
                    id: story.id,
                    title: story.title,
                    order_recommended: story.order_recommended
                };
            });
            this.storiesList = sortByAttrDesc(this.storiesList, 'order_recommended');
        },
        onStoryMoved() {
            return !this.dragLocked;
        },
        async onOrderChanged() {
            this.dragLocked = true;
            this.storiesList.forEach((story, index) => story.order_recommended = this.storiesList.length - index);
            this.storiesList = sortByAttrDesc(this.storiesList, 'order_recommended');

            const sortOrder = {};
            this.storiesList.forEach(story => sortOrder[story.id] = story.order_recommended);

            const response = await this.updateStoriesRecommendedOrder(sortOrder);
            this.dragLocked = false;

            if (!response.error)
                bus.$emit('show-notification-message', 'Порядок обновлен');
            else
                bus.$emit('show-notification-message', `Ошибка: ${response.error.toString()}`);
        }
    },
};
</script>

<style lang="scss">
.recommended-stories-page {
    &__list {
        display: flex;
        flex-direction: column;
    }

    .list-group {
        span {
            flex-direction: column;

            li {
                text-align: left;
                margin: 4px 0;
                border: 1px solid #867e7e;
            }
        }
    }
}
</style>
